
import { eoRendererMixin, metaDataMixin } from '~/mixins'

export default {
  mixins: [
    eoRendererMixin,
    metaDataMixin,
  ],
  layout: 'community-visie',
  scrollToTop: false,
  async asyncData ({ $api, $config, store, route: { path }, error }) {
    const {
      data: {
        items: page = [],
      } = {},
    } = await $api.pages.getOneBySlug('magazine', {
      domain: $config.preprEnvironmentDomainVisie,
    })

    // check publication if exists and is complete
    if (!page || !page.length) {
      error({
        context: `Couldn't create magazine page.`,
        message: '(404) Pagina niet gevonden',
        severity: 'info',
        statusCode: 404,
      })
      return false
    }

    store.dispatch('breadcrumbs/mirror', {
      path,
      mirror: `/nevenactiviteit/visie`,
    })

    return page[0]
  },
  data () {
    return {
      id: null,
      title: null,
      subtitle: null,
      intro: null,
      featuredVideo: null,
      hero: null,
      content: null,
      seo: null,
      slug: null,
      social: null,
    }
  },
}
